export const randomGames = [
  {
    id: 1,
    title: 'dadJoke1',
    metaTitle: '아재 개그 맞추기 게임',
    metaDesc: '멈춰서 나온 문제 못맞추면 한잔마셔!',
    category: 'dadJoke',
    lang: 'Kor',
    questions: [
      '물리학자가 웃을 때 내는 소리는?',
      '메이저리그에서 던지는 공은?',
      '모든 사람을 일어나게 하는 숫자는? ',
      '자동차를 톡하고 치면?',
      '등쳐먹고 사는 사람은? ',
      '소가 불에 타면? ',
      '오리가 얼면? ',
      '가장 비싼 새는?',
      '맥주가 죽기 전에 남긴 말은? ',
      '역대 가장 가난한 왕은? ',
      '반성문을 영어로 하면?',
      '화장실에서 금방 나온 사람은? ',
      '흑형의 여자친구는? ',
      '물고기를 가장 잘 낚는 일본인은? ',
      '생쥐가 네 마리 모이면?',
      '과소비가 가장 심한 동물은?',
      '곰은 사과를 어떻게 먹을까?',
      '가장 큰 콩은? ',
      '꿩 먹고 알도 먹는 사람은? ',
      '고양이가 죽어서 지옥에 가면?',
      '모든 싱글들의 짝은? ',
      '소가 웃는 소리를 3글자로? ',
      '1 더하기 1은? ',
      '매일 이상한 것을 보는 사람은? -',
      '땀을 흘릴 수록 작아지는 것은? ',
      '돼지가 열받으면 어떻게 될까? ',
      '담배가 경마장에 간 이유는? ',
      '진짜 새는? ',
      '가장 빨리 만들어지는 떡은? ',
      '가장 착한 사자는?',
      '추잡스럽기 짝이 없는 개는? ',
      '여름마다 일어나는 전쟁은? ',
      '논리적인 사람이 총을 쏘면? ',
      '슈렉 엄마의 이름은? ',
      '사람들이 가장 싫어하는 금은? ',
      '돼지가 방귀 뀌는 것은? ',
      '똥싸다 죽으면? ',
      '흑인이 자기소개를 하면? ',
      '자다가 나온 머리카락은? ',
      '남자는 코가 크면 뭐가 클까? ',
      '햄버거의 색깔은?',
      '수소가 암소를 밟았을 때? ',
      '포도가 자기소개 할 때 하는 말은? ',
      '무가 눈물을 흘릴 때 하는 말은?',
      '아몬드가 죽으면? ',
      '눈이 좋은 사슴은?',
      '소가 죽으면? ',
      '신이 화나면? ',
      '신이 아이를 낳으면? ',
      "'방귀뀌지마'를 영어로 한다면?",
      "'돌잔치'를 영어로 한다면? ",
      "'반성문'을 영어로 한다면?",
      '바람이 귀엽게 부는 동네는?',
      '꽃집사장들이 싫어하는 도시는? ',
      '프랑스에서 라면을 먹으면 안되는 이유는? ',
      '북유럽 사람들이 책을 많이 읽는 이유는? ',
      '대머리가 총을 한발밖에 못쏘는 이유는? ',
      '대머리들이 사라에 빠지면 안되는 이유는? - ',
      '논리적인 사람이 총을 쏜다면? ',
      '총을 대충 쏜다면? ',
      '학생들이 싫어하는 피자는? ',
      '물로 만든 고양이는?',
      '세상에서 가장 유명한 벌레는?',
      '소가 노래를 부르면?',
      '소들이 떼창을 하면?',
      '오리의 여왕이 일어서면? ',
      '오리를 생으로 먹으면? ',
      '인천 앞바다의 반대말은?',
      '고구려 역사상 가장 더러웠던 전투는? ',
      '시력이 좋은 사슴은? ',
      '석유의 유통기한은? ',
      '절에 없는 스님은? ',
      '화장실에서 방금 나온 사람은? ',
      '싸움을 가장 잘하는 나라는? ',
      '가장 억울한 도형은? ',
      '칼이 정색하면? ',
      '해를 취재하는 사람은?',
      '전화로 세운 건물 이름은? ',
      '혀가 거짓말을 하면? ',
      '연금술사들이 모이는 곳은? ',
      '알기 제일 쉬운 숫자는? ',
      '대머리가 싫어하는 매력은? ',
      '호주의 돈은?',
    ],
    answers: Array.from(
      { length: 12 },
      (_, i) => `https://images.ktestone.com/resultImages/dadJoke1/${i + 1}.jpg`
    ),
  },
  {
    id: 2,
    title: 'songTitle1',
    metaTitle: '노래 제목 맞추기 게임',
    metaDesc:
      '돌려서 나온 가사 보고 정답!!!\n외친 후 노래 맞추기!\n틀리면 1잔 마셔~',
    category: 'songTitle',
    lang: 'Kor',
    questions: [
      '해야 해야 해야',
      `Uhm we're not going home tonight, alright`,
      '어떤 인사가 괜찮을까 천 번쯤 상상해 봤어',
      '워 머리부터 발끝까지 다 사랑스러워',
      'DARUMDARIMDA 구름을 타고 여기저기로',
      'U U U U U U U U super 이끌림',
      '아무리 기다려도 난 못가 바보처럼',
      '있어야 할 건 다 있구요',
      '내가 더 많이 사랑한 죄 널 너무나',
      '예민하대 나 lately 너 없이는 매일매일이',
      '떠나는 그대여 울지 말아요 슬퍼 말아요',
      '정답은 없어 One hunnit 그런 기대감 내려놔',
      '평온했던 하늘이 무너지고',
      '그 모든게 내겐 예뻤어',
      '첫 만남은 너무 어려워 계획대로',
      '내가 먹고 싶었던 건 달디단',
      '세상에게서 도망쳐 Run on 나와 저 끝까지 가줘',
      '저 푸른바다 끝까지 말을 달리면 소금같은',
      '붉은색 푸른색 그 사이 3초 그 짧은 시간',
      '그리움이 쫓아 사랑은 늘 도망가',
      '너 다시 돌아와 주라 꼭 다시 돌아와 주라',
      '내꺼 하자 내가 널 사랑해 어',
      '나는 사실 그대에게 좋은 사람이 아녜요',
      '거짓말이라도 믿을게 영화 같은 스토리의 끝에',
      '불타오르네 싹 다 불태워라 Bow wow wow',
      'It must be L.O.V.E 200 percent',
      '생각이 많은 건 말이야 당연히 해야 할 일이야',
      'Sign을 보내 signal 보내',
      '온몸을 덮고 있는 가시 얼마나 힘이 들었으면',
      '사건은 다가와 Ah Oh Ay',
    ],
    answers: Array.from(
      { length: 5 },
      (_, i) =>
        `https://images.ktestone.com/resultImages/songTitle1/${i + 1}.jpg`
    ),
  },
  {
    id: 3,
    title: 'imagePoint1',
    metaTitle: '이미지 지목 게임',
    metaDesc: '돌려서 나온 이미지 보고 지목하기!\n지목 받은 사람 1잔 마셔~!',
    category: 'imagePoint',
    lang: 'Kor',
    questions: [
      '자주 안 씻을 것 같은 사람은?',
      '잠버릇 심할 것 같은 사람은?',
      '돈 많아지면 배신할 것 같은 사람은?',
      '짝사랑을 많이 했을 것 같은 사람은?',
      '부모님 속 좀 썩였을 것 같은 사람은?',
      '가장 연애 많이 해봤을 것 같은 사람은?',
      '사기 제일 많이 당할 것 같은 사람은?',
      '모태솔로일 것 같은 사람은?',
      '번호 많이 따여봤을 것 같은 사람은?',
      '어릴 때 삥 뜯겼을 것 같은 사람은?',
      '여기서 제일 또라이같은 사람은?',
      '제일 꼰대일 것 같은 사람은?',
      '첫인상 제일 별로였던 사람은?',
      '제일 밝힐 것 같은 사람은?',
      '똥쟁이 일 것 같은 사람은?',
      '내가 얘보다 낫다 생각이 드는 사람은?',
      '환불 제일 잘 할 것 같은 사람은?',
      '가장 금사빠일 것 같은 사람은?',
      '솔직히 여기서 제일 어색한 사람은?',
      '제일 꼰대일 것 같은 사람은?',
      '친구가 수배당하면 바로 신고할 것 같은 사람은?',
      '거짓말 제일 잘할 것 같은 사람은?',
      '술 버릇이 가장 고약할 것 같은 사람은?',
      '내 자식이라면 제일 속 터질 것 같은 사람은?',
      '낮과 밤이 가장 다를 것 같은 사람은?',
      '야구동영상 제일 많이 볼 것 같은 사람은?',
      '술 제일 잘 마실 것 같은 사람은?',
      '사기 쳐도 잘 넘어갈 것 같은 사람은?',
      '애인에게 집착할 것 같은 사람은?',
      '나한테 바로 돈 빌려줄 것 같은 사람은?',
    ],
    answers: null,
  },
  {
    id: 4,
    title: 'noExist1',
    metaTitle: '없다 랜덤 게임',
    metaDesc:
      '나는 ~한 적이 없다!\n해본 적 있으면 1잔 마셔~\n(안 해봤으면서 해본 척 걸리면 2잔!)',
    category: 'noExist',
    lang: 'Kor',
    questions: [
      '나는 키스를 해본 적이 없다!',
      '나는 술 먹고 울어본 적이 없다!',
      '나는 고백을 받아본 적이 없다!',
      '나는 롤러코스터를 타본 적이 없다!',
      '나는 번호를 따여본 적이 없다!',
      '나는 손톱을 물어 뜯어본 적이 없다!',
      '나는 이성과 포옹을 해본 적이 없다!',
      '나는 선생님께 혼나 본 적이 없다!',
      '나는 5명 이상 사귀어본 적이 없다!',
      '나는 해외여행을 가본 적이 없다!',
      '나는 머리를 묶어본 적이 없다!',
      '나는 환불을 해본 적이 없다!',
      '나는 헌팅 술집에 가본 적이 없다!',
      '나는 여기서 어색한 사람이 없다!',
      '나는 애니 보고 울어본 적이 없다!',
      '나는 연인에게 차여본 적이 없다!',
      '나는 짝사랑을 해본 적이 없다!',
      '나는 전 애인의 sns를 염탐해 본 적이 없다!',
      '나는 이성 친구한테 설레본 적이 없다!',
      '나는 환승 연애를 당해본 적이 없다!',
      '나는 전 애인한테 연락해 본 적이 없다!',
      '나는 다이어트를 해본 적이 없다!',
      '나는 친구들과 싸워본 적이 없다!',
      '나는 비트 코인을 해본 적이 없다!',
      '나는 야구 동영상을 본 적이 없다!',
      '나는 이성에게 플로팅을 해본 적이 없다!',
      '나는 무서움을 느껴본 적이 없다!',
      '나는 동물을 키워본 적이 없다!',
      '나는 자취를 해본 적이 없다!',
      '나는 친구에게 밥을 얻어먹은 적이 없다!',
    ],
    answers: null,
  },
  {
    id: 5,
    title: 'randomAlcohol1',
    metaTitle: '랜덤 술자리 게임',
    metaDesc: '돌려서 나온 가사 술자리 게임 ㄱㄱ!!',
    category: 'randomAlcohol',
    lang: 'Kor',
    questions: [
      '지하철 노선 말하기 게임',
      '출석부 게임',
      '백종원 레시피 게임',
      '배스킨라빈스 31',
      '손병호 게임',
      '훈민정음 게임',
      '산 넘어 산',
      '바니 바니 게임',
      '바보 게임',
      '궁예 게임',
      '클레오파트라 게임',
      '세 글자 게임',
      '경마 게임',
      '혼자 왔어요~ 게임',
      '레코드판 DJ 게임',
      '표창게임',
      '헷갈려게임',
      '금지어 게임',
      '아파트 게임',
      '소주 뚜껑 날리기 게임',
      '오렌지 게임',
      '만두 게임',
      '전국~노래자랑',
      '호빵 찐빵 대빵',
      '침묵의 007빵',
      '응답하라 게임',
      '시장에 가면',
      '옵션 걸기',
      '공동묘지',
      '슈퍼스타K 게임',
    ],
    answers: Array.from(
      { length: 5 },
      (_, i) =>
        `https://images.ktestone.com/resultImages/randomAlcohol1/${i + 1}.jpg`
    ),
  },
];

export const randomGamesRoutes = randomGames.map((item) => item.title);
