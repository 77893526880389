export const bingo = [
  {
    id: 1,
    title: 'idealTypeBingoFemale',
    displayTitle: '이상형 빙고판(여자편)',
    displaySubtitle: '나의 이상형을 빙고판에 체크해보자!',
    lang: 'Kor',
    size: [5, 5],
    shuffle: true,
    contents: [
      '팔 근육 힘줄',
      '보조개',
      '무쌍 눈',
      '무심츤츤',
      '미친 수트핏',
      '눈웃음',
      '스킨십 만땅',
      '동굴 보이스',
      '진한 이목구비',
      '소년미',
      '가수급 노래',
      '가르마 머리',
      '낮져밤이',
      '안경 찰떡',
      '주관 또렷',
      '주차 능숙',
      '교회 오빠 스타일',
      '집순돌이',
      '멍뭉미 대형견',
      '사투리',
      '연락 칼답',
      '헬스에 진심',
      '다정세심',
      '순애보',
      '이벤트 장인',
    ],
  },
  {
    id: 2,
    title: 'idealTypeBingoMale',
    displayTitle: '이상형 빙고판(남자편)',
    displaySubtitle: '나의 이상형을 빙고판에 체크해보자!',
    lang: 'Kor',
    size: [5, 5],
    shuffle: true,
    contents: [
      '똥꼬발랄 댕댕비',
      '칼단발 머리',
      '썡얼 미인',
      '글래머 몸매',
      '술찌',
      '무쌍 매력',
      '나만 보는 순애보',
      '눈 웃음',
      '동물 사랑',
      '인스타 중독',
      '겜순이',
      '손 예쁨',
      '애교 만땅',
      '고양이 상',
      '요리 잘함',
      '향수 냄새',
      '키작녀',
      '집순이',
      '짙은 쌍꺼풀',
      '오피스룩',
      '깡마른 모델 몸매',
      '연락 칼답',
      '연상 누나',
      '보조개',
      '태닝 피부',
    ],
  },
];

export const bingoList = bingo.map((elem) => elem.title);
